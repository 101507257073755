<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <div v-if="step === 1">
          <div class="has-text-right">
            <a class="has-text-danger" @click="$modal.close">Close</a>
          </div>
          <section class="content">
            <div class="has-text-centered section">
              <img
                :src="require('core/img/icons/upsell-options.svg')"
                alt="Upsell Modal"
                width="250"
              />
            </div>
            <p class="title is-4 has-text-primary">
              Find out the factory fitted options that were installed on this
              vehicle at the time of build
            </p>
            <p>
              Unlock deeper insights into a vehicle's true value with Percayso's
              vehicle options checks. Percayso's VIN level and normalised
              options dataset makes it easy to check a vehicle's specification
              and to compare against similar vehicles.
            </p>
          </section>
          <div class="buttons is-right">
            <button class="button is-rounded is-soft" @click="step = 2">
              Get started
            </button>
          </div>
        </div>
        <section v-else>
          <BusinessEnquiry :enquiry-message="enquiryMessage" />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanionOptionsUpsell',
  components: {
    BusinessEnquiry: () =>
      import('@/modules/contact/components/cazana/BusinessEnquiry')
  },
  data: () => ({ step: 1 }),
  computed: {
    enquiryMessage: () =>
      'Get access to your vehicle options data today and begin to price your vehicles more accurately'
  }
}
</script>
